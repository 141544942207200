.grid-chip-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  border-radius: 16px;
  background-color: #b9b9c3;
  font-weight: 600;
  font-size: 0.75rem;
}
