menu {
  position: fixed;
  width: 260px;
  height: calc(100 * var(--vh));
  padding: 24px;
  background: white;
  z-index: 2;
  border: 1px solid #ebe9f1;

  .main-logo {
    width: 100%;
  }

  .menu-container {
    margin-top: 32px;
  }

  .menu-items {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.menu-item-bttn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;

  svg {
    font-size: 1.3rem;
    color: var(--black);
  }

  p {
    font-weight: 500;
  }

  &.active {
    background: rgba(126, 115, 242, 0.12);
    border-radius: 8px;

    p, svg {
      color: var(--purple);
    }
  }
}
