.pets-tab {

  .name-cell {
    display: flex;
    align-items: center;
    gap: 12px;

    & > div {
      display: flex;
      flex-direction: column;
    }

    .owner-name-div {
      display: flex;
      align-items: center;
      gap: 8px;

      button {
        font-size: 1rem;
        display: flex;
        color: rgba(0, 0, 0, 0.54);
      }
    }
  }
}
