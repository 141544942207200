.MuiDataGrid-root {
  background-color: white;
}

.MuiDataGrid-columnHeaders {
  background-color: #f3f2f7;
}

.MuiDataGrid-booleanCell[data-value='false'] {
  color: #c3272b !important;
}

.MuiDataGrid-booleanCell[data-value='true'] {
  color: #5b8930 !important;
}
