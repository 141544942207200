.main-div {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
}

.tab-container {
  flex: 1;
  width: calc(100vw - 260px);
  margin-left: 260px;
  padding: 32px 32px 0px 32px;
  display: flex;
  flex-direction: column;
}
