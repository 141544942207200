.users-tab {
  .name-cell {
    display: flex;
    align-items: center;
    gap: 12px;

    & > div {
      display: flex;
      flex-direction: column;
    }

    .email-div {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}
