.login-page {
  flex: 1;
  width: 100%;

  .login-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
    background-color: white;
    border-radius: 30px;
    padding: 32px;
    width: 90%;
    max-width: 500px;

    .form-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
