.input-image {
  width: 100%;
  height: 240px;
  padding: 20px 0px;
  background: #f6f7fa;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg,
  img {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  input {
    display: none;
  }

  label {
    display: flex;
    justify-content: center;
  }

  .photo {
    height: 200px;
    max-width: 90%;
    border-radius: 30px;
  }
}
