.input-color {
  display: flex;
  gap: 8px;

  .current-color {
    width: 24px;
    height: 24px;
    border: 2px solid #9E95F5;
    border-radius: 50%;
  }
}
