.main-bttn {
  width: 100%;
  padding: 12px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  transition: opacity 0.2s;

  &.blue {
    background: linear-gradient(180deg, #3C58DA 0%, #4254A8 100%);
  }

  &.purple {
    background: linear-gradient(73.57deg, #7367F0 4.6%, #9E95F5 96.49%);
  }

  &.pink {
    background: #EA459E;
  }

  &.yellow {
    background: #FFBC01;
  }

  &.white {
    background: white;

    p {
      color: var(--purple);
    }
  }

  &:disabled {
    opacity: 0.7;
  }

  svg {
    color: white;
    font-size: 1.25rem;
  }

  p {
    color: white;
    font-weight: 500;
  }
}
