.create-contest {
  .form-wrapper {
    width: 100%;
    margin: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 60px 60px 0 0;
    box-shadow: 0 4px 24px rgb(0 0 0 / 6%);
    padding: 48px;
    max-width: 900px;
  }

  .input-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
